<template>
  <div class="flex">
    <LoadingButton variant="outline" @click.native="openModal" class="h-11 mr-2 whitespace-nowrap">
      Import
    </LoadingButton>

    <div
      v-if="showModal"
      tabindex="-1"
      aria-hidden="true"
      class="
        fixed
        top-0
        left-0
        right-0
        w-full
        p-4
        bg-black bg-opacity-50
        overflow-x-hidden overflow-y-auto
        md:inset-0
        md:h-full
        lift
      "
    >
      <div
        class="
          flex flex-column
          bg-white
          rounded-lg
          max-w-lg
          w-100
          min-height-min
          m-auto
          lift
          divide-y divide-gray-200
          relative
          mt-20
        "
      >
        <div class="p-4 flex flex-row justify-between">
          <h5>Import {{ service }}</h5>
          <a href="#" @click="closeModal" class="text-lg font-bold text-black opacity-50 hover:opacity-100 no-underline"
            >x</a
          >
        </div>
        <div class="flex flex-column py-4 px-6 gap-4">
          <Alert v-if="errorMessage" variant="red" icon="exclamation">
            <strong>Failed to upload file</strong>
            <p>{{ errorMessage }}</p>
          </Alert>

          <div class="center-spinner" :class="{ 'shrink-input': isUploading }">
            <LoadingSpinner size="sm" v-if="isUploading" class="mr-2" />
            <b-form-group :invalid-feedback="veeErrors.first('file-name')" class="w-full mb-8">
              <template v-slot:label> File Name </template>
              <b-form-input
                name="file-name"
                v-model="fileName"
                trim
                v-validate="{ min: 1, max: 36, required: true }"
                :state="validateState('file-name')"
                data-vv-as="file name"
                :disabled="success"
              />
            </b-form-group>

            <b-form-file
              name="input-orders-upload"
              v-model="ordersFile"
              accept=".csv"
              no-drop
              browse-text=".csv"
              :disabled="success"
            ></b-form-file>
            <a :href="sampleUrl" target="_blank" class="text-blue-500 my-2 ml-2">Download a sample file here</a>
          </div>
          <Alert v-if="success" variant="blue" icon="info" show>
            <p>
              The file will now be processed. Progress can be monitored on the
              <a href="/imports">imports page</a> as well as any errors that may occur during the import process.
            </p>
          </Alert>
        </div>
        <div class="px-2 py-3 mt-4 flex flex-row justify-end">
          <LoadingButton
            variant="default"
            @click.native="closeModal"
            class="h-11 mr-4 whitespace-nowrap"
            :disabled="disableCancelButton"
          >
            {{ success ? 'Close' : 'Cancel' }}
          </LoadingButton>
          <LoadingButton
            variant="green"
            :disabled="disableImportButton"
            :loading="importLoading"
            class="whitespace-nowrap mr-4"
            @click.native="uploadFile"
          >
            Import
          </LoadingButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/ui/LoadingButton';
import FileUploadServiceV2 from '@/lib/file-upload-service-v2';
import Alert from '@/components/ui/Alert';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import JobServiceV2 from '@/lib/job-service-v2';

export default {
  components: {
    Alert,
    LoadingButton,
    LoadingSpinner
  },
  props: {
    eventId: {
      type: String,
      required: false // not required by customer importer
    },
    service: {
      type: String,
      required: false
    },
    sampleUrl: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showModal: false,
      errorMessage: null,
      ordersFile: null,
      disabled: false,
      isUploading: false,
      isImporting: false,
      dryRunSuccessful: false,
      importSuccessful: false,
      s3Url: null,
      validationResponse: [],
      isContextBody: false,
      fileName: null,
      success: false
    };
  },
  computed: {
    importLoading() {
      return this.isImporting && this.importSuccessful;
    },
    disableImportButton() {
      if (!this.validateState('file-name')) {
        return true;
      }

      if (this.isUploading || this.success) {
        return true;
      }

      return !this.ordersFile || !this.fileName;
    },
    disableCancelButton() {
      return this.isImporting;
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      document.body.classList.add('overflow-hidden');
    },
    closeModal() {
      this.showModal = false;
      this.resetModal();
      document.body.classList.remove('overflow-hidden');
    },
    resetModal() {
      this.errorMessage = null;
      this.ordersFile = null;
      this.disabled = false;
      this.isUploading = false;
      this.isImporting = false;
      this.dryRunSuccessful = false;
      this.importSuccessful = false;
      this.fileName = null;
      this.success = false;

      this.s3Url = null;
      this.validationResponse = [];
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async createUploadUrl() {
      try {
        let body;

        if (this.service) {
          body = {
            service: this.service,
            eventId: this.eventId ?? undefined,
            fileName: this.fileName
          };
        }

        this.s3Url = await JobServiceV2.createJob(body);

        return this.s3Url;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async uploadFile() {
      // Reset previous details
      this.errorMessage = null;
      this.s3Url = null;
      this.importSuccessful = false;
      this.validationResponse = [];

      // Safeguard against empty uploads
      if (!this.ordersFile) {
        return;
      }

      try {
        this.isUploading = true;

        const uploadUrl = await this.createUploadUrl();

        await FileUploadServiceV2.uploadToS3(uploadUrl, this.ordersFile);
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      } finally {
        this.isUploading = false;
        this.success = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lift {
  z-index: 1050;
}

.shrink-input {
  .custom-file {
    display: inline-block;
    max-width: calc(100% - 3rem);
  }
}
.custom-file {
  display: inline-block;
}

.center-spinner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
</style>
